html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: inherit;
}

br {
  display: block;
  margin: 10px 0;
  content: " ";
}

.myTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
